const jobListEl = document.getElementById('jobList');
const jobFormEl = document.getElementById('jobForm');
const errorEl = document.getElementById('errorList');
const submitButtonEl = document.getElementById('submitButton');
let loadedJobs; //Variable to save loaded jobs in

//Create eventlistener for form
if (jobFormEl){
    jobFormEl.addEventListener('submit', handleInput);
}

//Get id from parent element
function getParentID(e){
    const target = e.parentElement.parentElement; //Get target
    const targetID = parseInt(target.id); //Get target id as a number
    return targetID;
}


//Load jobs
async function loadJobs(){
    try {
        const res = await fetch(`https://dt207g.u2.bayville.se/api/jobs/`);
        const data = await res.json();

        const jobs = data.jobs;
        
        //If jobs and job list element clear content and call function
        if (jobs && jobListEl) {
            loadedJobs = jobs;
            jobListEl.innerHTML = ""; //Clear content
            renderJobs(jobs); //Call render-function
            }
           
    } catch (error) {
        console.error('Error:', error);
    }

}

//Add job
async function addJob(formDataObject){
    
    //Send post request
    try {
        const res = await fetch(`https://dt207g.u2.bayville.se/api/jobs/`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(formDataObject)
        });
        
        // If succesful go to index
        if (res.status === 201) {
            window.location.href = "/";

        //Handle validation errors
        } else if (res.status === 400) { 
            const responseData = await res.json(); //Get response
            const validationErrors = responseData.errors; //Get errors

            errorEl.innerHTML = ""; //Clear error-list
            //Render each error in list
            validationErrors.forEach(error => {
                errorEl.innerHTML += `
                <li>${error.msg}</li>
                `
                console.error(`Valideringsfel: ${error.msg}`);
            });
        } else {
            console.error("Något gick fel när jobbet lades till");
        }
    } catch (error) {
        console.error(error);
    }
}

//Delete job
async function deleteJob(id){
    let confirm = window.confirm('Är du säker på att du vill ta bort jobbet?'); //Confirm dialog

    //If yes
    if (confirm){
        //Send delete request
        try {
            const res = await fetch(`https://dt207g.u2.bayville.se/api/jobs/${id}`,{
                method: 'DELETE',
            });
            
            //If deletion is succesful load jobs
            if (res.status === 204){
                loadJobs();
            } else {
                const errorData = await res.json();
                console.error(errorData.error);
            }
        } catch (error) {
            console.error(error.json());
        }
    }
}


//Get job to edit and navigate to add.html
function editJobs(jobId){
    const jobToEdit = loadedJobs.find(job => job.id === jobId); //Find job stored in variable

    sessionStorage.setItem('jobToEdit', JSON.stringify(jobToEdit)); //Save job to sessionstorage
    window.location.href = 'add.html'; //Navigate to add.html
}

//Save edited job to database
async function submitEdit(formDataObject, id){
    try {
        const res = await fetch(`https://dt207g.u2.bayville.se/api/jobs/${id}`,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(formDataObject)
        }); 
        //Succeful goto index and clear sessionStorage
        if (res.status === 200) {
            sessionStorage.removeItem('jobToEdit'); //Else remove item from sessionStorage
            window.location.href = "/";

        } else if (res.status === 400) { 
            const responseData = await res.json(); //Get response
            const validationErrors = responseData.errors; //Get errors

            errorEl.innerHTML = ""; //Clear error-list
            //Render each error in list
        
            validationErrors.forEach(error => {
                errorEl.innerHTML += `
                <li>${error.msg}</li>
                `
                console.error(`${error.msg}`);
            });

        } else if (res.status === 404) { 
            errorEl.innerHTML = `<li>Inget jobb med ID: ${id} hittades.`; //Render error if job not found

        }
    } catch (error) {
        console.error('Error:', error);
    }


}

//Render jobs
function renderJobs(jobs){
    if (jobs.length >= 1){
        jobs.sort((a, b) => new Date(b.startdate) - new Date(a.startdate)); //Sort array so newest startdate is on top
        jobs.forEach(job => {
            let endDateString;
            const startDateString = job.startdate.substring(0, 10); //Remove time from date
            const description = job.description.replace(/\n/g, "<br>"); //Add linebreaks

            if (job.enddate === null){
                endDateString = 'Pågående'; //If no enddate set enddate to "Pågende"
            } else{
                endDateString = job.enddate.substring(0, 10); //Remove time from date 
            }
            
            //Render job cards
            jobListEl.innerHTML += `
                <li id="${job.id}">
                    <div>
                        <h2 id="title-${job.id}">${job.jobtitle}</h2>
                        <p id="company-${job.id}">${job.companyname}</p>
                        <p class="job__details"><span id=startDate-${job.id}">${startDateString}</span> - <span id="endDate-${job.id}">${endDateString}</span></p>
                        <p class="job__details" id="location-${job.id}">${job.location}</p>
                        <p class="job_description" id="description-${job.id}">${description}</p>
                    </div>
                    <div class="job__actions">
                        <button class="outlined">Redigera</button>
                        <button class="delete">Ta bort</button>
                    </div>
                </li>
            `;
        });

        //Add eventlisteners to delete-buttons
        const deleteButtons = document.querySelectorAll('.delete');
        deleteButtons.forEach(item => {
            item.addEventListener('click', (e) => {
                const target = e.target;
                const parentID = getParentID(target);
                deleteJob(parentID);
            });
        });

        //Add eventlisteners to edit-buttons
        const editButtons = document.querySelectorAll('.outlined');
        editButtons.forEach(item => {
            item.addEventListener('click', (e) => {
                const target = e.target;
                const parentID = getParentID(target);
                editJobs(parentID);
            });
        });

    } else {
        jobListEl.innerHTML = "<li>Inga jobb tillagda just nu.</li>";
    }
}

//Handles from input
function handleInput(e){

       e.preventDefault(); //Prevent form submission
       const targetID = e.target.id; //Get form ID
    
       //Extract form data and store in object
       const formData = new FormData(e.target);
       const formDataObject = {};
       for (const [key, value] of formData.entries()) {
           formDataObject[key] = value;
       }
       
       //If form is not jobForm 
       if (targetID != 'jobForm') {
            submitEdit(formDataObject, targetID); //Call edit job
       } else {
            addJob(formDataObject); //Call add job
       }
}



//Call function to load jobs
document.addEventListener('DOMContentLoaded', loadJobs);

//Fills form when edit-button is clicked
document.addEventListener('DOMContentLoaded', () => {
    //Get job to edit from sessionStorage
    const jobToEdit = JSON.parse(sessionStorage.getItem('jobToEdit'));
    
    //If jobtoedit is found and jobFormEl exists fill form
    if (jobToEdit && jobFormEl){
        document.getElementById('companyName').value = jobToEdit.companyname;
        document.getElementById('jobTitle').value = jobToEdit.jobtitle;
        document.getElementById('location').value = jobToEdit.location;
        document.getElementById('startDate').value = jobToEdit.startdate.substring(0, 10);
        document.getElementById('endDate').value = (jobToEdit.enddate !== undefined && jobToEdit.enddate !== null) ? jobToEdit.enddate.substring(0, 10) : '';
        document.getElementById('description').value = jobToEdit.description;
        jobFormEl.setAttribute('id', jobToEdit.id); //Set from-id
        submitButtonEl.innerHTML = 'Ändra jobb';
    } else{
        sessionStorage.removeItem('jobToEdit'); //Else remove item from sessionStorage
    }
});


 
